html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
}

body.theme-default {
	--background: #0f0f23;
	--background-1: #1f1f3f;
	--background-2: #1f3f2f;
	--foreground: rgb(240, 240, 240);
	--color-1: rgb(255, 0, 0);
	--color-2: rgb(85, 221, 103);

	--gap: 0.6rem;
	--content-width: 500px;
	--radius: 5px;
	--boxshadow: 0 4px 8px 0 rgb(0, 0, 0);

	--menu-href-color: rgb(255, 255, 255);
	--menu-href-color-hover: rgb(200, 200, 200);
	--menu-sep-color: var(--color-1);
}

body {
	background: var(--background);
	background-image: url("background.jpg");
	background-repeat: repeat;
	background-attachment: fixed;
}

body,
button,
input,
textarea {
	color: var(--foreground);
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 20px;
}

button {
	&:hover {
		cursor: pointer;
	}
}

button.human {
	margin: 0;
	padding: var(--gap);
	background: var(--background);
	border: 1px dashed var(--background-1);
	border-radius: var(--radius);
	color: var(--foreground);
}

button.color-2 {
	margin: 0;
	padding: var(--gap);
	background: var(--color-2);
	color: black;
	border: 0;
	border-radius: var(--radius);
	cursor: pointer;
}

p {
	margin: 0;
	padding: 0;
}

ul,
li {
	list-style: none;
	margin: 0;
	padding: 0;
}

a,
a:visited,
a:active {
	color: var(--color-2);
	text-decoration: underline;

	&:hover {
		color: var(--foreground);
	}
}

.site-width {
	width: 95%;
	max-width: 1200px;
}

header.site {
	margin: 0 auto;

	ul,
	li {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	ul {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-end;
		gap: var(--gap);

		li {
			line-height: 4rem;
			text-transform: uppercase;
			font-size: 1rem;
			font-weight: bold;
			letter-spacing: 0.3rem;

			a {
				color: var(--menu-href-color);
				text-decoration: none;

				&:hover {
					color: var(--menu-href-color-hover);
				}
			}

			&.sep {
				color: var(--menu-sep-color);
			}
		}
	}
}

div.site-content {
	background: var(--background);
	border-radius: var(--radius);
	box-shadow: var(--boxshadow);
	width: 95%;
	margin: 0 auto;
}

div.pagetop {
	margin: 0 auto;
	margin-top: 2rem; // TODO: Fix with flex layout+gap.

	display: flex;
	justify-content: center;

	button.goup {
		margin: 0;
		padding: 0;
		background: transparent;
		border: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: var(--gap);
		cursor: pointer;
		font-size: 0.8rem;
		text-transform: uppercase;
		opacity: 0.5;

		&:hover {
			opacity: 1;
		}
	}
}

footer.site {
	margin: 0 auto;
	margin-top: 2rem; // TODO: Fix with flex layout+gap.
	margin-bottom: 2rem;

	background: rgba(0, 0, 0, 0.8);
	border-radius: var(--radius);
	box-shadow: var(--boxshadow);

	display: flex;
	flex-direction: row;
	font-size: 1rem;

	.c0,
	.c1,
	.c2 {
		flex: 1;

		h1 {
			all: unset;
			display: block;
			font-size: 1.8rem;
			margin-bottom: var(--gap);
		}

		.content {
			padding: calc(2 * var(--gap));
		}
	}

	.sitemap {

		ul,
		li {
			margin: 0;
			padding: 0;
			list-style: square;
		}

		ul {
			display: flex;
			flex-direction: column;
			gap: var(--gap);
		}

		li {
			margin-left: calc(2 * var(--gap));
		}
	}

	.attrib {

		dl,
		dt,
		dd {
			margin: 0;
			padding: 0;
		}

		dl {
			display: flex;
			flex-direction: column;
			gap: var(--gap);

			dt {
				font-weight: bold;
			}

			dd {
				margin-left: calc(2 * var(--gap));
			}
		}
	}
}

#home {
	margin: 0;
	padding: calc(2 * var(--gap));

	background-image: url("site-left-background.png");
	background-repeat: no-repeat;
	background-position: 0 30rem;

	&.two-columns {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		gap: calc(2 * var(--gap));

		.c0 {
			flex: 0 0 25rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: calc(4 * var(--gap));
		}

		.c1 {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: calc(4 * var(--gap));
		}
	}

	.profile-frame {
		background: var(--background-1);
		border-radius: var(--radius);
		padding: calc(2 * var(--gap));

		display: flex;
		flex-direction: column;
		gap: calc(2 * var(--gap));

		div:nth-child(1) {
			flex: 0;
			display: flex;

			&>div {
				flex: 1;

				&.name {
					font-weight: bold;
				}

				&.title {
					text-align: right;
				}
			}
		}

		div:nth-child(2) {
			flex: 0;

			img {
				border-radius: var(--radius);
			}
		}

		div:nth-child(3) {
			flex: 0;
			display: flex;
			gap: calc(4*var(--gap));
			justify-content: center;
		}

		div:nth-child(4) {
			display: flex;
			justify-content: center;

			button {
				flex: 1;
				margin: 0;
				padding: var(--gap);
				background: var(--color-2);
				color: black;
				border: 0;
				border-radius: var(--radius);
				cursor: pointer;
			}
		}
	}

	.contact-info {
		display: flex;
		flex-direction: column;
		gap: var(--gap);
		// padding: 2rem 0 0 6rem;

		dl,
		dt,
		dd {
			margin: 0;
			padding: 0;
		}

		dl {
			display: flex;
			align-items: center;
			gap: var(--gap);

			dt {
				display: flex;
				align-items: center;
			}

			dd {}
		}
	}

	section {
		display: flex;
		flex-direction: column;
		gap: calc(2 * var(--gap));

		h1,
		h2 {
			all: unset;
			margin: 0;
			padding: 0;
		}

		h1 {
			display: inline-block;
			padding: var(--gap);
			border: 1px solid var(--background-1);
			border-radius: var(--radius);
			background: rgb(31, 31, 63);
			background: linear-gradient(90deg, var(--background-1) 0%, transparent 70%);
		}

		h2 {
			display: inline-block;
			font-size: 2rem;

			&>span {
				color: var(--color-2);
			}
		}

		/* Class to apply on all direct content elements. */
		&>h2,
		&>p,
		&>.content {
			margin: 0 var(--gap) !important;
		}
	}

	section.introduce {
		p.slogan {
			text-align: center;
			font-style: italic;
		}
	}

	section.resume {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				list-style: none;
				padding: 0 0 1.5rem 20px;
				margin: 0 0 0 10px;
				border-left: 1px solid var(--background-1);
				position: relative;

				&:last-child {
					border: 0;
					padding: 0 0 0 20px;
				}

				&:before {
					content: '';
					width: 16px;
					height: 16px;
					background: var(--background);
					border: 1px solid var(--color-2);
					border-radius: 50%;
					position: absolute;
					left: -10px;
					top: 0.2rem;
				}

				.date {
					font-weight: bold;
				}
			}
		}
	}

	section.services {
		&>ul {
			display: flex;
			flex-direction: column;
			gap: calc(2 * var(--gap));

			&>li {
				display: flex;
				flex-direction: column;
				gap: calc(2 * var(--gap));

				div.title {
					background: var(--background-1);
					border-radius: var(--radius);
					padding: var(--gap);

					&>span {
						color: var(--color-2);
					}
				}

				&>p {
					margin: 0 var(--gap);
				}

				/* List inside with special points */
				&>ul {
					display: flex;
					flex-direction: column;
					gap: var(--gap);

					&>li {}

					&>li::before {
						content: "+";
						color: var(--color-2);
						padding: 0 var(--gap) 0 calc(2 * var(--gap));
					}
				}
			}
		}
	}

	section.skills {}

	section.contact {
		form {
			display: flex;
			flex-direction: column;
			gap: var(--gap);
			padding: var(--gap);

			input,
			textarea {
				flex: 0;
				background: transparent;
				border: 1px solid var(--background-1);
				border-radius: var(--radius);
				margin: 0;
				padding: var(--gap);
			}

			textarea {
				flex: 1 1 10rem;
				resize: vertical;
			}

			div.actions {
				display: flex;
				flex-direction: row;
				gap: var(--gap);

				button {
					flex: 1;
				}
			}
		}
	}

	ul.tags {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: var(--gap);

		li {
			flex: 0;
			border: 1px solid var(--foreground);
			border-radius: var(--radius);
			padding: var(--gap);
			white-space: nowrap;
		}
	}

	div.sep {
		border: 0 solid var(--background-1);
		border-width: 2px 0;
		height: 2px;
	}

	div.pagebottom {
		display: flex;
		justify-content: center;
		margin-bottom: calc(4 * var(--gap));
	}
}

#ts3video-page {
	display: flex;
	flex-direction: column;
	gap: var(--gap);

	div.screenshot {
		display: flex;
		justify-content: center;

		img {
			flex: 1;
		}
	}
}

div.layout-default {
	margin: 0;
	padding: calc(2 * var(--gap));

	h1 {
		display: block;
		font-weight: bold;
		font-size: 2rem;
		margin: 0 0 1rem 0;

		&:not(:first-child) {
			margin-top: 1rem;
		}
	}
}

div.layout-default-single {}

div.layout-default-list {
	ul {
		display: flex;
		flex-direction: column;
		gap: var(--gap);
		padding-left: calc(2 * var(--gap));

		li {
			list-style: square;
		}
	}
}

.markdown {

	h1,
	h2,
	h3,
	h4,
	p {
		all: unset;
		margin: 0;
		padding: 0;
	}

	h1,
	h2,
	h3,
	h4 {
		display: block;
		font-weight: bold;
		margin-bottom: 1rem;

		&:not(:first-child) {
			margin-top: 1rem;
		}
	}

	h1 {
		font-size: 1.8rem;
	}

	h2 {
		font-size: 1.6rem;
	}

	h3 {
		font-size: 1.4rem;
	}

	h4 {
		font-size: 1.2rem;
	}

	p {
		display: block;
	}
}